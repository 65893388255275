import { INTERACTION_TRACKING_RESPONSE_MAP } from '../../constants/tracking';

const trackSubmissionStatusSetup =
  (trackUserInteraction, canUseTracking) =>
  (outcomeString, pageType, source) => {
    if (!canUseTracking()) {
      return;
    }

    const { actionType, failureReason } =
      INTERACTION_TRACKING_RESPONSE_MAP[outcomeString];

    trackUserInteraction({ pageType, actionType, failureReason, source });
  };

export default trackSubmissionStatusSetup;
