import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LAYOUT } from '../../../../constants/layouts';
import { INTERACTION_ACTION_TYPE } from '../../../../constants/tracking';
import Form from '../../../common/Form';
import withTracking from '../../../common/WithTracking';
import Copy from '../Copy';
import Footer from '../Footer';

import STYLES from './Inline.scss';

class Inline extends Component {
  ref = React.createRef();

  componentDidMount() {
    this.props.trackUserInteraction({
      pageType: this.props.pageType,
      actionType: INTERACTION_ACTION_TYPE.RENDERED,
      source: this.props.source,
    });

    if (!this.ref || !this.ref.current) {
      return;
    }

    this.props.trackVisibleInBrowserViewport({
      pageType: this.props.pageType,
      currentRef: this.ref.current,
      actionType: INTERACTION_ACTION_TYPE.FORM_SEEN,
      source: this.props.source,
    });
  }

  render() {
    const {
      channel,
      currency,
      layout,
      locale,
      market,
      pageType,
      proposition,
      registerSubmissionAttempt,
      source,
      version,
    } = this.props;

    return (
      <div
        ref={this.ref}
        data-testid="inline-wrapper"
        className={STYLES.Inline}
      >
        <div className={STYLES.Inline__form}>
          <Copy />
          <Form
            market={market}
            locale={locale}
            layout={layout}
            source={source}
            channel={channel}
            currency={currency}
            version={version}
            pageType={pageType}
            proposition={proposition}
            registerSubmissionAttempt={registerSubmissionAttempt}
          />
          <Footer />
        </div>
        <div
          className={STYLES.Inline__image}
          style={{
            backgroundImage:
              "url('https://content.skyscnr.com/m/6ea151fbaf83db9e/original/Emailcapture.png')",
          }}
        />
      </div>
    );
  }
}

Inline.propTypes = {
  locale: PropTypes.string,
  market: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  source: PropTypes.string,
  channel: PropTypes.string,
  currency: PropTypes.string,
  version: PropTypes.string,
  pageType: PropTypes.string,
  proposition: PropTypes.string,
  registerSubmissionAttempt: PropTypes.func,
  trackUserInteraction: PropTypes.func,
  trackVisibleInBrowserViewport: PropTypes.func,
};

export default withTracking(Inline);
