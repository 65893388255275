/* eslint-disable global-require */
import { TRACKING_ENV } from '../../constants/env';
import { COMPONENT_NAME } from '../../constants/tracking';

const setupFunnelTracking = () => {
  // Requires are temporary hack to get the OC working on the registry
  const { FunnelEventsTracking } = require('saddlebag-user-tracking-events');

  return new FunnelEventsTracking(TRACKING_ENV, COMPONENT_NAME);
};

export default setupFunnelTracking;
