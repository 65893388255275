import { TRACKING_LOG } from '../constants/env';

const trackFunnelEvent = async (funnelEventTracker, schema, properties) => {
  const { guid } = await funnelEventTracker.track(schema, properties);
  TRACKING_LOG('Funnel event tracking', guid, schema, properties);
};

const trackMiniEvent = async (
  miniEventTracker,
  message,
  schema,
  properties,
) => {
  const { guid } = await miniEventTracker.track(message, schema, properties);
  TRACKING_LOG('Mini event tracking', guid, schema, message, properties);
};

export { trackFunnelEvent, trackMiniEvent };
