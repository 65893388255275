const ERROR_RESPONSES = ['ERROR'];

const ABSOLUTE_SANDBOX_URL =
  'https://marketing-capture.slingshot.eu-west-1.sandbox.aws.skyscanner.local/api/v1/emails';

const RELATIVE_PROD_URL = '/g/marketing-capture/api/v1/emails';

const SUCCESS_RESPONSES = [
  'MARKETING_EMAIL_CREATED',
  'MARKETING_EMAIL_ALREADY_EXISTS',
  'EXISTING_ACCOUNT_OPTED_IN',
  'EXISTING_ACCOUNT_ALREADY_OPTED_IN',
];

const AUTHENTICATED = 'AUTHENTICATED';
const UNAUTHENTICATED = 'UNAUTHENTICATED';

export {
  RELATIVE_PROD_URL,
  ABSOLUTE_SANDBOX_URL,
  SUCCESS_RESPONSES,
  ERROR_RESPONSES,
  AUTHENTICATED,
  UNAUTHENTICATED,
};
