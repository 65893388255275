import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LAYOUT } from '../../../constants/layouts';
import {
  INTERACTION_ACTION_TYPE,
  INTERACTION_FAILURE_REASONS,
} from '../../../constants/tracking';
import FooterFormPresentational from '../../layouts/footer/FooterFormPresentational';
import InlineFormPresentational from '../../layouts/inline/InlineFormPresentational';
import ModalFormPresentational from '../../layouts/modal/ModalFormPresentational';
import withTracking from '../WithTracking';

import EmailValidator from './emailValidator';

class FormPresentational extends Component {
  state = this.props.initState || {
    email: '',
    inputAlreadyTracked: false,
  };

  onEmailChange = (evt) => {
    if (this.state.inputAlreadyTracked) {
      this.setState({
        email: evt.currentTarget.value,
      });
    } else {
      this.props.trackUserInteraction({
        pageType: this.props.pageType,
        actionType: INTERACTION_ACTION_TYPE.EMAIL_ENTERED,
        source: this.props.source,
      });
      this.setState({
        email: evt.currentTarget.value,
        inputAlreadyTracked: true,
      });
    }
  };

  onEmailClear = () =>
    this.setState({
      email: '',
    });

  onSubmit = (evt) => {
    evt.preventDefault();
    this.props.onSubmit(this.state.email);
  };

  trackInvalidEmailEntry = (isValid) => {
    if (!isValid && this.state.email) {
      this.props.trackUserInteraction({
        pageType: this.props.pageType,
        actionType: INTERACTION_ACTION_TYPE.FAILED,
        failureReason: INTERACTION_FAILURE_REASONS.failedFrontendValidation,
        source: this.props.source,
      });
    }
  };

  render() {
    const {
      onEmailChange,
      onEmailClear,
      onSubmit,
      props: {
        buttonLabel,
        clearButtonLabel,
        isLoading,
        isSuccess,
        layout,
        message,
        placeholder,
      },
      state: { email },
    } = this;

    const isValid = email.length > 0 ? EmailValidator.isValid(email) : null;
    const isInputDisabled = isSuccess || isLoading;
    const isButtonDisabled = !isValid || isSuccess || isLoading;

    const layoutComponentCommonProps = {
      onSubmit,
      isSuccess,
      isLoading,
      placeholder,
      clearButtonLabel,
      buttonLabel,
      message,
      email,
      trackInvalidEmailEntry: this.trackInvalidEmailEntry,
      isValid,
      onEmailChange,
      onEmailClear,
      isInputDisabled,
      isButtonDisabled,
    };

    if (layout === LAYOUT.FOOTER) {
      return <FooterFormPresentational {...layoutComponentCommonProps} />;
    }

    if (layout === LAYOUT.INLINE) {
      return <InlineFormPresentational {...layoutComponentCommonProps} />;
    }

    if (layout === LAYOUT.MODAL || layout === LAYOUT.TWO_STEP) {
      return <ModalFormPresentational {...layoutComponentCommonProps} />;
    }

    return null;
  }
}

FormPresentational.propTypes = {
  buttonLabel: PropTypes.string,
  clearButtonLabel: PropTypes.string,
  initState: PropTypes.shape({
    email: PropTypes.string,
    inputAlreadyTracked: PropTypes.bool,
  }),
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  onSubmit: PropTypes.func,
  message: PropTypes.string,
  pageType: PropTypes.string,
  placeholder: PropTypes.string,
  source: PropTypes.string,
  trackPaidMarketing: PropTypes.func,
  trackUserInteraction: PropTypes.func,
  trackVisibleInBrowserViewport: PropTypes.func,
  trackConsentStatus: PropTypes.func,
  trackSubmissionStatus: PropTypes.func,
};

export default withTracking(FormPresentational);
