import { pageProperties } from '../../services/browser';
import { publish } from '../../services/observer';

export const setupPublisherEvents =
  (
    canUseTracking,
    versionedProposition,
    pageType,
    isPaidMarketing,
    isNewUser,
  ) =>
  (topic: String, data) => {
    if (!canUseTracking() || !isPaidMarketing) {
      return;
    }

    publish(topic, {
      value_proposition: versionedProposition,
      page_type: pageType,
      page_url: pageProperties.getUrl(),
      paid_marketing: isPaidMarketing,
      is_new_user: isNewUser,
      ...data,
    });
  };
