export const LAYOUT = Object.freeze({
  MODAL: 'modal',
  INLINE: 'inline',
  TWO_STEP: 'twostep',
  FOOTER: 'footer',
});

// All layout types that use the modal flow (i.e. a page takeover as part of the flow)
// These are grouped because they must not interfere with any GDPR cookie collection
export const MODAL_FLOW = [LAYOUT.MODAL, LAYOUT.TWO_STEP];
