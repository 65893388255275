import { SUCCESS_RESPONSES } from '../../../../constants/api';

const getMessageForOutcome = (outcomeString, messages): ?string => {
  if (!outcomeString) {
    return null;
  }

  if (SUCCESS_RESPONSES.includes(outcomeString)) {
    return messages.SUCCESS_MESSAGE;
  }

  if (outcomeString === 'INVALID_EMAIL') {
    return messages.INVALID_EMAIL_MESSAGE;
  }

  return messages.ERROR_MESSAGE;
};

export default getMessageForOutcome;
