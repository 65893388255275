import React from 'react';

const AppContext = React.createContext({
  market: 'UK',
  proposition: 'find-help',
  version: '0',
  pageType: 'routes-from-to',
  isPaidMarketing: false,
  isNewUser: undefined,
});

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;
