import React from 'react';
import PropTypes from 'prop-types';

import BpkCloseButton from 'bpk-component-close-button';

import { I18nConsumer } from '../../../common/I18n';

import STYLES from './ModalHeader.scss';

const ModalHeader = ({ onClose }) => (
  <I18nConsumer>
    {({ MODAL_CLOSE_BUTTON_LABEL }) => (
      <header
        data-testid="email-capture-header-container"
        className={STYLES.ModalHeader}
      >
        <BpkCloseButton
          className={STYLES.ModalHeader__closeModalButton}
          label={MODAL_CLOSE_BUTTON_LABEL}
          data-testid="modal-close-button"
          onClick={onClose}
        />
      </header>
    )}
  </I18nConsumer>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func,
};

export default ModalHeader;
