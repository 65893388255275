import React from 'react';
import PropTypes from 'prop-types';

import { LAYOUT } from '../../constants/layouts';
import { AppContextProvider } from '../common/AppContext';
import { I18nProvider } from '../common/I18n';
import Footer from '../layouts/footer/Footer';
import Inline from '../layouts/inline/Inline';
import Modal from '../layouts/modal/Modal';
import TwoStep from '../layouts/twoStep/TwoStep';

import defaultStings from './defaultStrings';

class App extends React.Component {
  state = {
    emailSubmissionAttempted: false,
  };

  registerSubmissionAttempt = () => {
    this.setState({ emailSubmissionAttempted: true });
  };

  render() {
    const {
      channel,
      currency,
      isNewUser,
      isPaidMarketing,
      layout,
      locale,
      market,
      pageType,
      proposition,
      source,
      staticPath,
      strings,
      version,
    } = this.props;

    const { emailSubmissionAttempted } = this.state;

    const layoutComponentCommonProps = {
      layout,
      emailSubmissionAttempted,
      staticPath,
      pageType,
      market,
      locale,
      source: isPaidMarketing ? `${source}-paid` : source,
      channel,
      currency,
      proposition,
      version,
      isPaidMarketing,
      isNewUser,
      registerSubmissionAttempt: this.registerSubmissionAttempt,
    };

    return (
      <AppContextProvider
        value={{
          market,
          proposition,
          version,
          pageType,
          isPaidMarketing,
          isNewUser,
        }}
      >
        <I18nProvider strings={{ ...defaultStings, ...strings }}>
          {layout === LAYOUT.MODAL && <Modal {...layoutComponentCommonProps} />}
          {layout === LAYOUT.INLINE && (
            <Inline {...layoutComponentCommonProps} />
          )}
          {layout === LAYOUT.FOOTER && (
            <Footer {...layoutComponentCommonProps} />
          )}
          {layout === LAYOUT.TWO_STEP && (
            <TwoStep source={layoutComponentCommonProps.source}>
              <Modal {...layoutComponentCommonProps} />
            </TwoStep>
          )}
        </I18nProvider>
      </AppContextProvider>
    );
  }
}
App.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
  market: PropTypes.string,
  locale: PropTypes.string,
  source: PropTypes.string,
  channel: PropTypes.string,
  currency: PropTypes.string,
  staticPath: PropTypes.string,
  version: PropTypes.string,
  pageType: PropTypes.string,
  proposition: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  isPaidMarketing: PropTypes.boolean,
  isNewUser: PropTypes.boolean,
};

export default App;
