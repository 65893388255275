import {
  RELATIVE_PROD_URL,
  ABSOLUTE_SANDBOX_URL,
  AUTHENTICATED,
  UNAUTHENTICATED,
} from '../../../../constants/api';
import { PROTOBUF_JSON } from '../../../../constants/contentTypes';
import { IS_PROD } from '../../../../constants/env';

const API_URL = IS_PROD ? RELATIVE_PROD_URL : ABSOLUTE_SANDBOX_URL;

const redirectIfBlocked = async (response) => {
  const payload = await response.json();
  if (payload.reason && payload.reason === 'blocked' && payload.redirect_to) {
    window.location.assign(payload.redirect_to);
  }
};

const perimeterXFetch = async (url, options) => {
  const response = await window.fetch(API_URL, options);
  const FORBIDDEN = 403;
  if (response.status === FORBIDDEN) {
    await redirectIfBlocked(response);
  }
  return response;
};

const captureEmail = async ({
  channel,
  currency,
  email,
  isLoggedIn,
  locale,
  market,
  proposition,
  source,
  utid,
  version,
}) => {
  // eslint-disable-next-line global-require
  const { getCsrfToken } = require('saddlebag-user-management');
  const csrf = getCsrfToken();
  const options = {
    body: JSON.stringify({
      identity: {
        utid,
        authenticationStatus: isLoggedIn ? AUTHENTICATED : UNAUTHENTICATED,
        isTrusted: isLoggedIn,
      },
      email,
      culture: {
        market,
        locale,
        currency,
      },
      consent: {
        source,
        channel,
        value_proposition: `${version}_${proposition}`,
      },
    }),
    method: 'POST',
    headers: {
      Accept: PROTOBUF_JSON,
      'Content-Type': PROTOBUF_JSON,
      ...(csrf ? { 'x-csrf-token': csrf } : {}),
    },
  };

  const response = await perimeterXFetch(API_URL, options);

  return response.json();
};

export default captureEmail;
