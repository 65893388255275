import logger from 'saddlebag-logger';

import { IS_PROD } from './constants/env';
import { MODAL_FLOW } from './constants/layouts';
import { loadExternalScripts } from './scriptLoader';
import { attachCookieOnDismissedListeners } from './utils/cookieBannerListeners';
import getProps from './utils/getProps';
import { isCookieBannerOnScreen } from './utils/isCookieBannerOnScreen';
import { isModalSeen } from './utils/localStorage';

const COMPONENT_NAME = 'email-capture';

(async () => {
  if (IS_PROD) {
    try {
      await loadExternalScripts();
    } catch (missing) {
      logger.logError({
        message: `Failed to load dependencies ${missing}`,
        level: 'error',
        componentName: COMPONENT_NAME,
      });
      return;
    }
  }

  const renderComponent = async () => {
    const React = await import(/* webpackMode: "eager" */ 'react');
    const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');

    const { default: Modal } = await import(
      /* webpackMode: "eager" */ './components/App'
    );

    const container = document.getElementById(`${COMPONENT_NAME}-root`);
    const props = getProps(COMPONENT_NAME);

    if (container !== null) {
      ReactDOM.hydrate(React.createElement(Modal, props), container);
    }
  };

  const tryToRenderModalFlow = async () => {
    if (!isCookieBannerOnScreen() && !isModalSeen()) {
      // eslint-disable-next-line no-use-before-define
      detachCookieOnDismissedListeners();
      await renderComponent();
    }
  };

  const detachCookieOnDismissedListeners =
    attachCookieOnDismissedListeners(tryToRenderModalFlow);

  try {
    const { debug, layout } = getProps(COMPONENT_NAME);

    if (!debug && MODAL_FLOW.includes(layout)) {
      tryToRenderModalFlow();
      return;
    }

    await renderComponent();
  } catch (err) {
    detachCookieOnDismissedListeners();

    if (IS_PROD) {
      logger.logError({
        message: `Failed to render component: ${err}`,
        level: 'error',
        componentName: COMPONENT_NAME,
      });
    } else {
      throw err;
    }
  }
})();
