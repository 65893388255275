import React from 'react';
import PropTypes from 'prop-types';

import BpkImage from 'bpk-component-image';

import SignupImage from './images/signup-image.svg';

import STYLES from './ImageContainer.scss';

const ImageContainer = ({ staticPath }) => (
  <div className={STYLES.ImageContainer} data-testid="signup-image-container">
    <BpkImage
      altText="electronics and coffee"
      data-testid="signup-image"
      width={150}
      height={150}
      src={`${staticPath}${SignupImage}`}
    />
  </div>
);

ImageContainer.propTypes = {
  staticPath: PropTypes.string,
};

ImageContainer.defaultProps = {
  // eslint-disable-next-line
  staticPath: '',
};

export default ImageContainer;
