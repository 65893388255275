/* eslint-disable global-require */
// Requires are temporary hack to get the OC working on the registry
import { LOGGER } from '../../../../constants/env';

const travellerLoggedIn = async () => {
  const { isLoggedIn } = require('saddlebag-user-management');
  let loggedIn = false;

  try {
    loggedIn = await isLoggedIn();
  } catch (error) {
    LOGGER.error(`Failed to call isLoggedIn: ${error}\n${error.stack}`);
  }

  return loggedIn;
};

const travellerUtid = (): string => {
  const { getUtid } = require('saddlebag-user-management');
  return getUtid();
};

export { travellerLoggedIn, travellerUtid };
