const COMPONENT_NAME = 'email-capture';
const TRACKING_SANDBOX = 'public-sandbox';
const TRACKING_PROD = 'public';

const INTERACTION_SCHEMA_PREFIX = 'email_capture_tracking';

const EMAIL_CAPTURE_SIGNUP_EVENT = 'email-capture-signup';

const INTERACTION_ACTION_TYPE = Object.freeze({
  RENDERED: 'RENDERED',
  FORM_SEEN: 'FORM_SEEN',
  MODAL_SHOWN: 'MODAL_SHOWN',
  MODAL_CLOSED: 'MODAL_CLOSED',
  EMAIL_ENTERED: 'EMAIL_ENTERED',
  SUCCESS: 'SUBMISSION_SUCCESSFUL',
  FAILED: 'SUBMISSION_FAILED',
  CTA_SHOWN: 'CTA_SHOWN',
  CTA_CLOSED: 'CTA_CLOSED',
  CTA_CLICKED: 'CTA_CLICKED',
});

const INTERACTION_FAILURE_REASONS = {
  noError: 'UNSET_FAILURE_REASON',
  duplicate: 'DUPLICATE_EMAIL',
  failedBackendValidation: 'FAILED_BACKEND_VALIDATION',
  failedFrontendValidation: 'FAILED_FRONTEND_VALIDATION',
  generalError: 'SUBMISSION_ERROR',
};
const INTERACTION_TRACKING_RESPONSE_MAP = {
  UNSET: {
    actionType: INTERACTION_ACTION_TYPE.FAILED,
    failureReason: INTERACTION_FAILURE_REASONS.generalError,
  },
  MARKETING_EMAIL_CREATED: {
    actionType: INTERACTION_ACTION_TYPE.SUCCESS,
    failureReason: INTERACTION_FAILURE_REASONS.noError,
  },
  MARKETING_EMAIL_ALREADY_EXISTS: {
    actionType: INTERACTION_ACTION_TYPE.FAILED,
    failureReason: INTERACTION_FAILURE_REASONS.duplicate,
  },
  EXISTING_ACCOUNT_OPTED_IN: {
    actionType: INTERACTION_ACTION_TYPE.SUCCESS,
    failureReason: INTERACTION_FAILURE_REASONS.noError,
  },
  EXISTING_ACCOUNT_ALREADY_OPTED_IN: {
    actionType: INTERACTION_ACTION_TYPE.FAILED,
    failureReason: INTERACTION_FAILURE_REASONS.duplicate,
  },
  INVALID_EMAIL: {
    actionType: INTERACTION_ACTION_TYPE.FAILED,
    failureReason: INTERACTION_FAILURE_REASONS.failedBackendValidation,
  },
};

const CONSENT_SCHEMA_NAME = 'marketing_opt_in_consent.MarketingOptInConsent';
const CONSENT_TRACKING_RESPONSE_MAP = {
  UNSET: 'OPT_IN_FAILED',
  MARKETING_EMAIL_CREATED: 'YES',
  MARKETING_EMAIL_ALREADY_EXISTS: 'UNSET_OPT_IN_STATE',
  EXISTING_ACCOUNT_OPTED_IN: 'YES',
  EXISTING_ACCOUNT_ALREADY_OPTED_IN: 'UNSET_OPT_IN_STATE',
  INVALID_EMAIL: 'OPT_IN_FAILED',
  NO_CHANGE_MADE: 'UNSET_OPT_IN_STATE',
};

export {
  COMPONENT_NAME,
  CONSENT_SCHEMA_NAME,
  INTERACTION_ACTION_TYPE,
  INTERACTION_FAILURE_REASONS,
  INTERACTION_SCHEMA_PREFIX,
  INTERACTION_TRACKING_RESPONSE_MAP,
  CONSENT_TRACKING_RESPONSE_MAP,
  TRACKING_SANDBOX,
  TRACKING_PROD,
  EMAIL_CAPTURE_SIGNUP_EVENT,
};
