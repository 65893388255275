import PropTypes from 'prop-types';
import React from 'react';

const I18nConsumer = ({ children }, { strings }) => children(strings);

I18nConsumer.contextTypes = { strings: PropTypes.objectOf(PropTypes.string) };

class I18nProvider extends React.Component {
  getChildContext() {
    return {
      strings: this.props.strings,
    };
  }

  render() {
    return this.props.children;
  }
}

I18nProvider.childContextTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
};

I18nProvider.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};

export default { I18nConsumer, I18nProvider };
