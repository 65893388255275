const trackVisibleInBrowserViewportSetup =
  (trackUserInteraction, canUseTracking) =>
  ({ actionType, currentRef, source }) => {
    if (!canUseTracking()) {
      return;
    }

    if (
      typeof IntersectionObserver === 'undefined' ||
      !currentRef ||
      !actionType ||
      !source
    ) {
      return;
    }

    const NOT_IN_VIEW = 0;
    const FULL_VISIBILITY = 1.0;
    const BROWSER_VIEWPORT = null;
    const NO_MARGIN = '0px';
    new IntersectionObserver(
      (entries, observer) => {
        if (
          entries &&
          entries[0] &&
          entries[0].intersectionRatio <= NOT_IN_VIEW
        ) {
          return;
        }
        trackUserInteraction({
          actionType,
          source,
          failureReason: 'UNSET_FAILURE_REASON',
        });
        if (observer) {
          observer.unobserve(currentRef);
          observer.disconnect();
        }
      },
      {
        root: BROWSER_VIEWPORT,
        rootMargin: NO_MARGIN,
        threshold: FULL_VISIBILITY,
      },
    ).observe(currentRef);
  };

export default trackVisibleInBrowserViewportSetup;
