const IS_NODE = typeof window === 'undefined';
const noop = () => {};

const saddlebagObserver = IS_NODE
  ? { publish: noop, subscribe: noop }
  : require('saddlebag-observer').default;

export const { publish } = saddlebagObserver;
export const { subscribe } = saddlebagObserver;

export default saddlebagObserver;
