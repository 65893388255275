export default {
  COPY_HEADER: 'We will explore the world again',
  COPY_CONTENT:
    'Stay one step ahead with our travel news, tips and deals emails.',
  FORM_EMAIL_PLACEHOLDER: 'Email address',
  FORM_EMAIL_CLEAR_BUTTON: 'Clear',
  FORM_BUTTON_LABEL: 'Sign up',
  MODAL_OPEN_BUTTON_LABEL: 'Get email alerts',
  MODAL_CLOSE_BUTTON_LABEL: 'Close sign up message',
  TWO_STEP_COPY_CONTENT:
    'Dreaming of your next trip? Make the perfect plans with insider tips, tools, news and deals',
  TWO_STEP_DISMISS_BUTTON_LABEL: 'Dismiss',
  ERROR_MESSAGE: 'Something’s gone wrong. Please try again later',
  SUCCESS_MESSAGE: "Great, you'll get the first email soon",
  INVALID_EMAIL_MESSAGE: 'Please enter a valid email address',
  UNSUBSCRIBE: 'You can unsubscribe at any time.',
  PRIVACY_POLICY:
    'Read our @@openLinkTag@@Privacy Policy@@closeLinkTag@@ for more info.',
};
