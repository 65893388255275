const IS_NODE = typeof window === 'undefined';
const getUrl = () => {};

const saddlebagBrowser = IS_NODE
  ? {
      pageProperties: {
        getUrl,
      },
    }
  : require('saddlebag-browser');

export const { pageProperties } = saddlebagBrowser;

export default saddlebagBrowser;
