import {
  CONSENT_SCHEMA_NAME,
  CONSENT_TRACKING_RESPONSE_MAP,
} from '../../constants/tracking';
import { trackFunnelEvent } from '../tracking';

import setupFunnelTracking from './setupFunnelTracking';

const trackConsentStatusSetup = (canUseTracking) => (outcomeString, source) => {
  if (!canUseTracking()) {
    return;
  }

  // We need to create the logger inside this function is order to exectute this code on the server.
  // This can be changed when dependency injection is implemented
  const createTrackingObject = setupFunnelTracking();

  const optInState = CONSENT_TRACKING_RESPONSE_MAP[outcomeString];
  trackFunnelEvent(createTrackingObject, CONSENT_SCHEMA_NAME, {
    source,
    email_opt_in: optInState || 'UNSET_OPT_IN_STATE',
    push_opt_in: 'UNSET_OPT_IN_STATE',
  });
};

export default trackConsentStatusSetup;
