import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BpkModal from 'bpk-component-modal';

import { LAYOUT } from '../../../../constants/layouts';
import {
  CONSENT_TRACKING_RESPONSE_MAP,
  INTERACTION_ACTION_TYPE,
} from '../../../../constants/tracking';
import { AppContextProvider } from '../../../common/AppContext';
import Form from '../../../common/Form';
import withTracking from '../../../common/WithTracking';
import Copy from '../Copy';
import Footer from '../Footer';
import ImageContainer from '../ImageContainer';
import ModalHeader from '../ModalHeader';

import STYLES from './Modal.scss';

const addElementToTheDocument = (id) => {
  if (typeof document === 'undefined') return;

  const element = document.createElement('div');
  element.setAttribute('id', id);
  if (document.body) {
    document.body.appendChild(element);
  }
};

const removeElementFromTheDocument = (id) => {
  if (typeof document === 'undefined') return;

  const element = document.getElementById(id);
  if (element) {
    element.remove();
  }
};

class Modal extends Component {
  // This is used to add aria-hidden=true on to the main element in the microsite. As mentioned in the docs for backpack modal:
  // "The pagewrap element id is a convention we use internally at Skyscanner." https://backpack.github.io/components/modal?platform=web
  // So it is a safe bet to set this to "pagewrap".
  applicationElement = 'pagewrap';

  modalTriggerElement = 'email-capture-trigger';

  state = {
    // Current behaviour is that modal is open by default and display is controlled externally
    isOpen: true,
  };

  componentDidMount() {
    this.props.trackUserInteraction({
      pageType: this.props.pageType,
      actionType: INTERACTION_ACTION_TYPE.MODAL_SHOWN,
      source: this.props.source,
    });
    // eslint-disable-next-line global-require
    const { trackModalAsSeen } = require('../../../../utils/localStorage');
    trackModalAsSeen();
  }

  componentWillUnmount() {
    removeElementFromTheDocument(this.modalTriggerElement);
  }

  onClose = () => {
    if (!this.props.emailSubmissionAttempted) {
      this.props.trackConsentStatus(
        CONSENT_TRACKING_RESPONSE_MAP.NO_CHANGE_MADE,
        this.props.source,
      );
    }

    this.props.trackUserInteraction({
      pageType: this.props.pageType,
      actionType: INTERACTION_ACTION_TYPE.MODAL_CLOSED,
      source: this.props.source,
    });

    this.setState({
      isOpen: false,
    });
  };

  createModalElement() {
    if (typeof document === 'undefined') return;

    const modalTrigger = document.getElementById(this.modalTriggerElement);

    if (!modalTrigger) {
      addElementToTheDocument(this.modalTriggerElement);
    }
  }

  render() {
    const {
      channel,
      currency,
      isNewUser,
      isPaidMarketing,
      layout,
      locale,
      market,
      pageType,
      proposition,
      registerSubmissionAttempt,
      source,
      staticPath,
      version,
    } = this.props;

    this.createModalElement();

    return (
      <div data-testid="modal-wrapper">
        <BpkModal
          className={STYLES.Modal}
          contentClassName={STYLES.Modal__content}
          // This testid isn't being passed through by the component. Backpack Modal component needs updating
          data-testid="modal"
          id="email-capture-modal"
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          closeOnScrimClick={false}
          getApplicationElement={() =>
            document.getElementById(this.applicationElement)
          }
          renderTarget={() => document.getElementById(this.modalTriggerElement)}
          fullScreenOnMobile={false}
          showHeader={false}
        >
          {/*
          We are using a provider again here, because the <BpkModal /> is losing the context.
          We believe this is down to the <BpkModal /> custom implementation of portals.
          */}
          <AppContextProvider
            value={{
              market,
              proposition,
              version,
              pageType,
              isPaidMarketing,
              isNewUser,
            }}
          >
            <ModalHeader onClose={this.onClose} />
            <ImageContainer staticPath={staticPath} />
            <Copy />
            <Form
              market={market}
              locale={locale}
              layout={layout}
              source={source}
              channel={channel}
              currency={currency}
              version={version}
              pageType={pageType}
              proposition={proposition}
              registerSubmissionAttempt={registerSubmissionAttempt}
            />
            <Footer />
          </AppContextProvider>
        </BpkModal>
      </div>
    );
  }
}

Modal.propTypes = {
  emailSubmissionAttempted: PropTypes.bool,
  locale: PropTypes.string,
  market: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  source: PropTypes.string,
  channel: PropTypes.string,
  currency: PropTypes.string,
  version: PropTypes.string,
  pageType: PropTypes.string,
  proposition: PropTypes.string,
  isPaidMarketing: PropTypes.bool,
  isNewUser: PropTypes.bool,
  staticPath: PropTypes.string,
  registerSubmissionAttempt: PropTypes.func,
  trackConsentStatus: PropTypes.func,
  trackUserInteraction: PropTypes.func,
};

export default withTracking(Modal);
