import React from 'react';
import PropTypes from 'prop-types';

import BpkBannerAlert, { ALERT_TYPES } from 'bpk-component-banner-alert';
import BpkButton from 'bpk-component-button';
import BpkInput, { CLEAR_BUTTON_MODES, INPUT_TYPES } from 'bpk-component-input';
import { BpkSpinner, SPINNER_TYPES } from 'bpk-component-spinner';

import STYLES from './InlineFormPresentational.scss';

const InlineFormPresentational = ({
  buttonLabel,
  clearButtonLabel,
  email,
  isButtonDisabled,
  isInputDisabled,
  isLoading,
  isSuccess,
  isValid,
  message,
  onEmailChange,
  onEmailClear,
  onSubmit,
  placeholder,
  trackInvalidEmailEntry,
}) => (
  <form
    className={STYLES.InlineForm}
    onSubmit={onSubmit}
    data-testid="email-capture-inline-form-layout"
  >
    <BpkInput
      className={`${STYLES.InlineForm__email}${
        isSuccess ? ` ${STYLES['InlineForm__email--success']}` : ''
      }`}
      id="email"
      data-testid="email-capture-input"
      type={INPUT_TYPES.email}
      name="email"
      value={email}
      onBlur={() => trackInvalidEmailEntry(!!isValid)}
      onChange={onEmailChange}
      placeholder={placeholder}
      clearButtonMode={CLEAR_BUTTON_MODES.whileEditing}
      clearButtonLabel={clearButtonLabel}
      onClear={onEmailClear}
      valid={isValid}
      disabled={isInputDisabled}
      aria-disabled={isInputDisabled}
    />
    {!isSuccess && (
      <BpkButton
        className={STYLES.InlineForm__signUp}
        type="submit"
        data-testid="email-capture-submit-button"
        disabled={isButtonDisabled}
        aria-disabled={isButtonDisabled}
      >
        {isLoading ? (
          <BpkSpinner
            type={SPINNER_TYPES.primary}
            alignToButton
            data-testid="email-capture-submit-button-spinner"
          />
        ) : (
          buttonLabel
        )}
      </BpkButton>
    )}
    {message && (
      <div
        data-testid="email-capture-response-message"
        className={STYLES.InlineForm__responseMessage}
      >
        <BpkBannerAlert
          animateOnEnter
          message={message}
          type={isSuccess ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR}
        />
      </div>
    )}
  </form>
);

InlineFormPresentational.propTypes = {
  buttonLabel: PropTypes.string,
  clearButtonLabel: PropTypes.string,
  email: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
  isInputDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onEmailClear: PropTypes.func,
  onSubmit: PropTypes.func,
  message: PropTypes.string,
  placeholder: PropTypes.string,
  trackInvalidEmailEntry: PropTypes.func,
};

export default InlineFormPresentational;
