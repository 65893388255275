// @jest strict
import { COMPONENT_NAME } from '../constants/tracking';

const IS_NODE = typeof window === 'undefined';
// This is a temporary hack to enable this code to run on the server.
// `saddlebag-logger` uses `saddlebag-browser` that internally has crypto as dependency.
const noop = () => {};
const { ERROR_LEVEL, logError, logWarn } = IS_NODE
  ? { logError: noop, logWarn: noop, ERROR_LEVEL: {} }
  : require('saddlebag-logger');

export const ERROR_LEVELS = ERROR_LEVEL;

export const log = {
  error: (message: string): Promise =>
    logError({
      message,
      level: ERROR_LEVELS.ERROR,
      component: COMPONENT_NAME,
    }),
  warn: (message: string): Promise =>
    logWarn({
      message,
      component: COMPONENT_NAME,
    }),
  info: (message: string): Promise =>
    logError({
      message,
      level: ERROR_LEVELS.INFO,
      component: COMPONENT_NAME,
    }),
  debug: (message: string): Promise =>
    logError({
      message,
      level: ERROR_LEVELS.DEBUG,
      component: COMPONENT_NAME,
    }),
};

export default log;
