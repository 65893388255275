import React from 'react';

import replace from 'react-replace-placeholder';

import BpkLink from 'bpk-component-link';
import BpkText from 'bpk-component-text';

import { I18nConsumer } from '../../../common/I18n';
import {
  getSkyscannerPrivacyPolicyUrl,
  getWindowObject,
} from '../../../helpers';

const Footer = () => {
  const privacyPolicyLink = getSkyscannerPrivacyPolicyUrl(getWindowObject());

  const termsAndPrivacyParams = {
    openLinkTag: {
      pair: 'closeLinkTag',
      format: (children) => (
        <BpkLink
          href={privacyPolicyLink}
          blank
          data-testid="email-capture-link-privacy-policy"
          key="email-capture-link-privacy-policy"
        >
          {children}
        </BpkLink>
      ),
    },
  };

  return (
    <I18nConsumer>
      {({ PRIVACY_POLICY, UNSUBSCRIBE }) => {
        const termsAndPrivacyText = replace(
          PRIVACY_POLICY,
          termsAndPrivacyParams,
        );

        return (
          <footer data-testid="email-capture-footer-container">
            <BpkText tagName="p" textStyle="sm">
              <span data-testid="email-capture-footer-unsubscribe">
                {UNSUBSCRIBE}
              </span>{' '}
              <span data-testid="email-capture-footer-privacy">
                {termsAndPrivacyText}
              </span>
            </BpkText>
          </footer>
        );
      }}
    </I18nConsumer>
  );
};

export default Footer;
