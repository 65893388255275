/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LAYOUT } from '../../../constants/layouts';
import { SUCCESS_RESPONSES } from '../../../constants/api';
import { LOGGER } from '../../../constants/env';
import { EMAIL_CAPTURE_SIGNUP_EVENT } from '../../../constants/tracking';
import { I18nConsumer } from '../I18n';
import withTracking from '../WithTracking';

import captureEmail from './api/captureEmail';
import getMessageForOutcome from './api/getMessageForOutcome';
import { travellerLoggedIn, travellerUtid } from './api/traveller';
import FormPresentational from './FormPresentational';

const isSuccessfulOutcome = (outcome) => !!SUCCESS_RESPONSES.includes(outcome);

class Form extends Component {
  state = {
    isSuccess: false,
    isLoading: false,
    outcomeString: null,
  };

  onSubmit = async (email) => {
    this.setState({
      isLoading: true,
    });

    const isLoggedIn = await travellerLoggedIn();
    const utid = travellerUtid();
    const { pageType } = this.props;

    const request = {
      ...this.props,
      ...{
        utid,
        isLoggedIn,
        email,
      },
    };

    try {
      const { outcome } = await captureEmail(request);

      this.props.trackConsentStatus(outcome, this.props.source);
      this.props.trackSubmissionStatus(outcome, pageType, this.props.source);

      const isSuccess = isSuccessfulOutcome(outcome);

      if (isSuccess) {
        const payload = {
          source: this.props.source,
        };
        this.props.trackPaidMarketing(EMAIL_CAPTURE_SIGNUP_EVENT, payload);
      }

      this.setState({
        isSuccess,
        outcomeString: outcome,
        isLoading: false,
      });
    } catch (error) {
      const outcomeString = 'UNSET';

      this.props.trackConsentStatus(outcomeString, this.props.source);
      this.props.trackSubmissionStatus(
        outcomeString,
        pageType,
        this.props.source,
      );

      this.setState({
        isSuccess: false,
        isLoading: false,
        outcomeString,
      });

      LOGGER.error(`Failed to capture email: ${error}\n${error.stack}`);
    }

    this.props.registerSubmissionAttempt();
  };

  render() {
    const {
      onSubmit,
      state: { isLoading, isSuccess, outcomeString },
    } = this;

    return (
      <I18nConsumer>
        {(strings) => {
          const {
            FORM_BUTTON_LABEL,
            FORM_EMAIL_CLEAR_BUTTON,
            FORM_EMAIL_PLACEHOLDER,
          } = strings;
          return (
            <FormPresentational
              onSubmit={onSubmit}
              isSuccess={isSuccess}
              isLoading={isLoading}
              layout={this.props.layout}
              placeholder={FORM_EMAIL_PLACEHOLDER}
              clearButtonLabel={FORM_EMAIL_CLEAR_BUTTON}
              buttonLabel={FORM_BUTTON_LABEL}
              message={getMessageForOutcome(outcomeString, strings)}
              pageType={this.props.pageType}
              source={this.props.source}
            />
          );
        }}
      </I18nConsumer>
    );
  }
}

Form.propTypes = {
  locale: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  source: PropTypes.string,
  channel: PropTypes.string,
  currency: PropTypes.string,
  version: PropTypes.string,
  pageType: PropTypes.string,
  proposition: PropTypes.string,
  registerSubmissionAttempt: PropTypes.func,
  trackPaidMarketing: PropTypes.func,
  trackUserInteraction: PropTypes.func,
  trackVisibleInBrowserViewport: PropTypes.func,
  trackConsentStatus: PropTypes.func,
  trackSubmissionStatus: PropTypes.func,
};

export default withTracking(Form);
