import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BpkButton from 'bpk-component-button';
import BpkCloseButton from 'bpk-component-close-button';
import BpkText from 'bpk-component-text';

import { INTERACTION_ACTION_TYPE as INTERACTION } from '../../../../constants/tracking';
import { I18nConsumer } from '../../../common/I18n';
import withTracking from '../../../common/WithTracking';

import STYLES from './TwoStep.scss';

class TwoStep extends Component {
  timer;

  constructor() {
    super();
    this.state = {
      showSecondStep: false,
      isVisible: false,
    };
  }

  componentDidMount() {
    this.timer = setTimeout(this.showCta, 2000);
    this.track(INTERACTION.CTA_SHOWN);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onButtonClick = () => {
    this.setState({ showSecondStep: true });
    this.track(INTERACTION.CTA_CLICKED);
  };

  onDismiss = () => {
    this.setState({ isVisible: false });
    this.track(INTERACTION.CTA_CLOSED);
  };

  track = (actionType) => {
    this.props.trackUserInteraction({
      actionType,
      source: this.props.source,
    });
  };

  showCta = () => {
    this.setState({ isVisible: true });
  };

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    if (this.state.showSecondStep) {
      return <>{this.props.children}</>;
    }

    return (
      <div data-testid="two-step-wrapper">
        <I18nConsumer>
          {({
            COPY_CONTENT,
            COPY_HEADER,
            FORM_BUTTON_LABEL,
            MODAL_CLOSE_BUTTON_LABEL,
          }) => (
            <div className={STYLES.TwoStep}>
              <BpkCloseButton
                className={STYLES.TwoStep__dismissbutton}
                label={MODAL_CLOSE_BUTTON_LABEL}
                onClick={this.onDismiss}
              />
              <div className={STYLES.TwoStep__contentwrapper}>
                <BpkText
                  className={STYLES.TwoStep__title}
                  tagName="h2"
                  textStyle="base"
                  bold
                  role="heading"
                >
                  {COPY_HEADER}
                </BpkText>
                <BpkText tagName="p">{COPY_CONTENT}</BpkText>
              </div>
              <div className={STYLES.TwoStep__actionwrapper}>
                <BpkButton
                  className={STYLES.TwoStep__button}
                  onClick={this.onButtonClick}
                  secondary
                >
                  {FORM_BUTTON_LABEL}
                </BpkButton>
              </div>
            </div>
          )}
        </I18nConsumer>
      </div>
    );
  }
}

TwoStep.propTypes = {
  source: PropTypes.string,
  children: PropTypes.node,
  trackUserInteraction: PropTypes.func,
};

export default withTracking(TwoStep);
