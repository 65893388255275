export const DEFAULT_SKYSCANNER_TLD = '.net';

export const getWindowObject = () => {
  const windowObject =
    typeof window !== 'undefined'
      ? window
      : {
          location: {},
        };

  return windowObject;
};

export const getOrigin = (windowObj) => `${windowObj.location.protocol}//${
  windowObj.location.hostname
}\
${windowObj.location.port ? `:${windowObj.location.port}` : ''}`;

export const getSkyscannerTopLevelDomain = (windowObj) => {
  const origin = getOrigin(windowObj);
  const skyscannerOriginParts = origin.split('skyscanner');

  return skyscannerOriginParts.length === 2
    ? skyscannerOriginParts[1]
    : DEFAULT_SKYSCANNER_TLD;
};

export const getSkyscannerPrivacyPolicyUrl = (windowObj) => {
  const tld = getSkyscannerTopLevelDomain(windowObj);

  return `https://www.skyscanner${tld}/privacypolicy.aspx`;
};
