import { LocalStorage } from 'saddlebag-localstorage';

import { SEEN_KEY, NAMESPACE } from '../constants/localStorage';

const localStorage = new LocalStorage(NAMESPACE);

export const trackModalAsSeen = () =>
  localStorage.trySetValue(SEEN_KEY, +new Date(), true);

export const isModalSeen = () => localStorage.tryGetValue(SEEN_KEY, true);
