import { INTERACTION_SCHEMA_PREFIX } from '../../constants/tracking';
import { pageProperties } from '../../services/browser';
import { trackMiniEvent } from '../tracking';

import setupMiniEventTracking from './setupMiniEventTracking';

export const trackUserInteractionSetup =
  (
    canUseTracking,
    versionedProposition,
    pageType,
    isPaidMarketing,
    isNewUser,
  ) =>
  ({ actionType, failureReason = 'UNSET_FAILURE_REASON', source }) => {
    if (!canUseTracking()) {
      return;
    }

    // We need to create the logger inside this function is order to exectute this code on the server.
    // This can be changed when dependency injection is implemented
    const createTrackingObject = setupMiniEventTracking();

    const properties = {};
    properties.value_proposition = versionedProposition;
    properties.source = source;
    properties.page_type = pageType;
    properties.page_url = pageProperties.getUrl();
    properties.action_type = actionType;
    properties.failure_reason = failureReason;
    properties.paid_marketing = isPaidMarketing;

    if (isNewUser === true || isNewUser === false) {
      properties.is_new_user = isNewUser;
    }

    trackMiniEvent(
      createTrackingObject,
      'UserAction',
      `${INTERACTION_SCHEMA_PREFIX}.UserAction`,
      properties,
    );
  };
