import logger from '../services/logger';

import { RELATIVE_PROD_URL, ABSOLUTE_SANDBOX_URL } from './api';
import { TRACKING_SANDBOX, TRACKING_PROD } from './tracking';

const IS_PROD = process.env.NODE_ENV === 'production';
const IS_DEV = process.env.NODE_ENV === 'development';

const API_URL = IS_PROD ? RELATIVE_PROD_URL : ABSOLUTE_SANDBOX_URL;
const LOGGER = IS_PROD ? logger : console;
const TRACKING_ENV = IS_PROD ? TRACKING_PROD : TRACKING_SANDBOX;
// eslint-disable-next-line no-console
const TRACKING_LOG = (...args: any) => (IS_DEV ? console.log(...args) : null);

export { API_URL, IS_DEV, IS_PROD, LOGGER, TRACKING_ENV, TRACKING_LOG };
