import { SingleValueCookie } from 'saddlebag-cookie';

import { GDPR_KEY, GDPR_INITIAL_STATE } from '../constants/cookie';

export const isCookieBannerOnScreen = () => {
  const cookie = new SingleValueCookie(GDPR_KEY);
  const cookieValue = cookie.getValue();
  if (cookieValue && cookieValue.length) {
    return cookieValue.includes(GDPR_INITIAL_STATE);
  }

  return false;
};
