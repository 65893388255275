import observer from 'saddlebag-observer';

import {
  COOKIE_POLICY_ACCEPTED,
  TRACKING_PREFERENCES_CHANGED,
} from '../constants/cookie';

const attachCookieOnDismissedListeners = (onDismissedHandler) => {
  const onTrackingPreferencesChangedHandler = observer.subscribe(
    TRACKING_PREFERENCES_CHANGED,
    onDismissedHandler,
  );

  const onCookiePolicyAcceptedHandler = observer.subscribe(
    COOKIE_POLICY_ACCEPTED,
    onDismissedHandler,
  );

  return () => {
    observer.unsubscribe(onTrackingPreferencesChangedHandler);
    observer.unsubscribe(onCookiePolicyAcceptedHandler);
  };
};

export { attachCookieOnDismissedListeners };
