import * as React from 'react';

import trackConsentStatusSetup from '../../../analytics/funnelEvents/trackConsentStatusSetup';
import trackSubmissionStatusSetup from '../../../analytics/miniEvents/trackSubmissionStatusSetup';
import { trackUserInteractionSetup } from '../../../analytics/miniEvents/trackUserInteractionSetup';
import { setupPublisherEvents } from '../../../analytics/publisherEvents/setupPublisherEvents';
import { getPreferences } from '../../../services/tracking';
import trackVisibleInBrowserViewportSetup from '../../../utils/trackVisibleInBrowserViewportSetup';
import { AppContextConsumer } from '../AppContext';

const withTracking = (WrappedComponent) => (props) =>
  (
    <AppContextConsumer>
      {({
        isNewUser,
        isPaidMarketing,
        market,
        pageType,
        proposition,
        version,
      }) => {
        const canUseTracking = () => {
          const preferences = getPreferences(market);

          return (
            preferences &&
            (!preferences.isGdprActiveInMarket ||
              preferences.information === true)
          );
        };

        const versionedProposition = `${version}_${proposition}`;

        const trackUserInteraction = trackUserInteractionSetup(
          canUseTracking,
          versionedProposition,
          pageType,
          isPaidMarketing,
          isNewUser,
        );
        const trackConsentStatus = trackConsentStatusSetup(canUseTracking);
        const trackVisibleInBrowserViewport =
          trackVisibleInBrowserViewportSetup(
            trackUserInteraction,
            canUseTracking,
          );
        const trackSubmissionStatus = trackSubmissionStatusSetup(
          trackUserInteraction,
          canUseTracking,
        );

        const publishEvent = setupPublisherEvents(
          canUseTracking,
          versionedProposition,
          pageType,
          isPaidMarketing,
          isNewUser,
        );

        return (
          <WrappedComponent
            trackPaidMarketing={publishEvent}
            trackConsentStatus={trackConsentStatus}
            trackSubmissionStatus={trackSubmissionStatus}
            trackUserInteraction={trackUserInteraction}
            trackVisibleInBrowserViewport={trackVisibleInBrowserViewport}
            {...props}
          />
        );
      }}
    </AppContextConsumer>
  );

export default withTracking;
