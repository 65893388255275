const TRACKING_PREFERENCES_CHANGED = 'tracking-preferences-changed';
const COOKIE_POLICY_ACCEPTED = 'cookie-policy-accepted';
const GDPR_KEY = 'gdpr';
const GDPR_INITIAL_STATE = 'init:::true';

export {
  TRACKING_PREFERENCES_CHANGED,
  COOKIE_POLICY_ACCEPTED,
  GDPR_KEY,
  GDPR_INITIAL_STATE,
};
