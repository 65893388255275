import React from 'react';

import BpkText from 'bpk-component-text';

import { I18nConsumer } from '../../../common/I18n';

import STYLES from './Copy.scss';

const Copy = () => (
  <I18nConsumer>
    {({ COPY_CONTENT }) => (
      <div className={STYLES.Copy}>
        <BpkText
          data-testid="email-capture-header-text"
          tagName="h2"
          bold
          className={STYLES.Copy__header}
          // we need to hardcode this id because we can't override aria-labelledby
          id="bpk-modal-heading-email-capture-modal"
        >
          {COPY_CONTENT}
        </BpkText>
      </div>
    )}
  </I18nConsumer>
);

export default Copy;
